import React from 'react';

import FacebookButton from '../FacebookButton/FacebookButton';
import GoogleButton from '../GoogleButton/GoogleButton';
import TwitterButton from '../TwitterButton/TwitterButton';
import LinkedinButton from '../LinkedinButton/LinkedinButton';

import { useAuth0Wrapper } from '../../utils/auth0';
import { useLocation } from 'react-router-dom';

const SocialButtons = ({ preserveLoginInitURL = false }) => {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0Wrapper();
  const login = (opts) => {
    if (preserveLoginInitURL) localStorage.setItem('loginInitURL', location.pathname);
    loginWithRedirect(opts);
  };

  return (
    <>
      <FacebookButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => login({ authorizationParams: { connection: 'facebook' } })}
        data-testid="socialButtonFacebook"
      >
        Facebook
      </FacebookButton>
      <GoogleButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => login({ authorizationParams: { connection: 'google-oauth2' } })}
        data-testid="socialButtonGoogle"
      >
        Google
      </GoogleButton>
      <TwitterButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => login({ authorizationParams: { connection: 'twitter' } })}
        data-testid="socialButtonTwitter"
      >
        X (Twitter)
      </TwitterButton>
      <LinkedinButton
        classList={{ root: 'col-12-xs' }}
        onClick={() => login({ authorizationParams: { connection: 'linkedin' } })}
        data-testid="socialButtonLinkedin"
      >
        LinkedIn
      </LinkedinButton>
    </>
  );
};

SocialButtons.displayName = 'socialButtons';
export default SocialButtons;
